








































import { Component, Prop, Vue } from 'vue-property-decorator'
import ClassCard from '@/components/ClassCard.vue'
import ClassEdit from '@/components/ClassEdit.vue'

@Component({
  name: 'ProgramDetails',
  components: {
    ClassCard,
    ClassEdit
  },
  data: () => ({
    // canAdd: false
  }),
  methods: {
    /* addClass: function () {
      this.$store.commit('createClassInCurrentProgram')
    } */
  }
})
export default class ProgramDetails extends Vue {
  @Prop() private currentProgram!: any

  get canAdd () {
    const classLimit = this.$store.getters.getConfig('classLimit', 2)
    if (this.$store.state.dataLoaded) {
      return false
    }
    if (classLimit && this.$props.currentProgram) {
      return this.$props.currentProgram.classes.length < classLimit
    }
    return true
  }

  get welcomeText () {
    const defaultText = 'Welcome to the classes page. Start by selecting the \'Add Class\' button. To edit or delete a class select the class tile, select \'Edit Details\'.'
    return this.$store.getters.getConfig('classIntroText', defaultText)
  }

  classAdded () {
    this.$router.push(`/class/${this.currentProgram.classes.length}`)
  }
}
