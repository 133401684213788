








import { Component, Prop, Vue } from 'vue-property-decorator'
import ProgramDetails from '@/components/ProgramDetails.vue'

@Component({
  components: {
    ProgramDetails
  },
  data: () => ({
    mini: true
  })
})
export default class Program extends Vue {
  @Prop() private programId!: number
  // @Prop() private mini!: boolean

  get currentProgram (): any {
    return this.$store.state.currentProgram
  }

  created () {
    this.fetchData()
  }

  beforeRouteUpdate (to: string, from: string, next: Function) {
    this.fetchData()
    if (this.$store.state.currentProgram) {
      this.$store.state.breadcrumbs.program = this.$store.state.currentProgram.title
    }
    next()
  }

  public fetchData (): void {
    // this.lesson = this.$store.state.currentLesson
    // this.loading = true
    if (!this.$store.state.dataLoaded) {
      return
    }
    this.$store.commit(
      'setCurrentProgram', this.$store.getters.getPlannerDataByProgramId(this.$store.state.programId)
    )
    // replace `getLesson` with your data fetching util / API wrapper
    // getLesson(fetchedId, (err, lesson) => {
    // make sure this request is the last one we did, discard otherwise
    // if (this.$route.params.id !== fetchedId) return
    // this.loading = false
    /* if (err) {
        this.error = err.toString()
      } else {
        this.lesson = lesson
      } */
    // })
  }
}
