









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'

@Component({
  name: 'ClassCard',
  data: () => ({
    // username: 'Josh'
  })
})
export default class ClassCard extends Vue {
  dialog = false
  @Prop() private classItem!: any

  get stageAndMilestoneTitle (): string {
    return this.$store.getters.getStageAndMilestoneTitle(this.classItem.milestoneId)
  }

  get startDateFormatted () {
    return this.classItem.startDate ? moment(this.classItem.startDate).format('DD/MM/YYYY') : ''
  }

  get endDateFormatted () {
    return this.classItem.endDate ? moment(this.classItem.endDate).format('DD/MM/YYYY') : ''
  }

  deleteClass (item: any) {
    this.$store.commit('deleteClass', item)
    this.$data.dialog = false
  }
}
